<template>
	<div>
		<template v-if="this.options?.name && this.options?.name?.length > 0">
			<label
					:for="this.options?.slug"
					class="input-unit__label"
					:class="{'required': this.options?.required}"
			>
				<span>
					{{ this.options?.name }}
				</span>
				<span
						v-if="this.options?.secondaryLabel && this.options?.secondaryLabel?.length > 0"
						class="input-unit__label--secondary"
				>
					{{ this.options?.secondaryLabel }}
				</span>
			</label>
		</template>

		<div
				class="checkbox-unit"
				:class="{
				[this.options?.class ?? '']: true,
				'checked': this.modelValue.toString() === 'true',
				'no-checked': this.modelValue.toString() === 'false',
				'disabled': this.options?.disabled ?? false,
				'checkbox-unit--success': this.options?.successState ?? false,
				'error': this.options?.errorState ?? false,
				'checkbox-unit--warning': this.options?.warningState ?? false,
				'checkbox-unit--info': this.options?.infoState ?? false,
			}"
		>
			<input
					ref="checkbox-input"
					type="checkbox"
					:name="this.options?.slug"
					:id="this.options?.slug"
					class="checkbox-unit__input"
					:checked="this.modelValue"
					:required="this.options?.required ?? false"
					:disabled="this.options?.disabled ?? false"
					@change='this.$emit("update:modelValue", $event.target.checked)'
			>
			<template v-if="this.options?.customTitle">
				<label v-html="this.options?.title" :for="this.options?.slug ?? ''"></label>
			</template>
			<label v-else class="checkbox-unit__label" :for="this.options?.slug ?? ''">
				{{ this.options?.title }}
			</label>
		</div>

		<small
				v-show="this.options?.infoText && this.options?.infoText?.length > 0"
				:id="this.options?.slug+'_info'"
				class="mt-1 checkbox-unit__label--secondary"
		>
			{{ this.options?.infoText }}
		</small>
	</div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {CheckboxUnitOptions} from "./Interfaces/CheckboxUnitOptions";

export default defineComponent({
	name: "CheckboxUnit",
	emits: ['click', 'update:modelValue'],
	props: {
		options: {
			type: Object as PropType<CheckboxUnitOptions>,
			required: true,
		},
		modelValue: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		modelValue(newValue: boolean) {
			(this.$refs["checkbox-input"] as HTMLInputElement).setAttribute('checked', newValue.toString());
		},
	},
});
</script>

<style scoped>
</style>
